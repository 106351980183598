import { sortList } from "@local/extensions/collections/sortable-list.js";

function creatRecordList() {
  let subscribers = [];
  const obj = { order: [], indices: {}, totalCount: 0 };

  function notify() {
    subscribers.forEach((subscriber) => {
      subscriber(obj);
    });
  }

  function subscribe(listener) {
    listener(obj);
    subscribers.push(listener);
    return (subscriber) => {
      const index = subscribers.indexOf(subscriber);
      if (index !== -1) subscribers.splice(index, 1);
    };
  }

  function load(recordArr) {
    Object.keys(obj).forEach((key) => delete obj[key]);
    obj.order = [];
    obj.indices = {};

    recordArr.forEach((record, index) => {
      obj.order.push(record.id);
      obj[record.id] = record;
      obj.indices[record.id] = index;
    });

    notify();
  }

  function insert(recordArr) {
    recordArr.forEach((record) => {
      if (!obj[record.id]) {
        obj.order.push(record.id);
        obj.indices[record.id] = obj.order.length - 1;
      }

      obj[record.id] = record;
    });

    notify();
  }

  function loadOne(record, index) {
    const recordExists = obj[record.id];
    obj[record.id] = record;

    if (!recordExists) {
      if (index !== undefined) {
        obj.order.splice(index, 0, record.id);
      } else {
        obj.order.push(record.id);
      }

      obj.indices = obj.order.reduce((h, id, index) => {
        h[id] = index;
        return h;
      }, {});
    }

    notify();
  }

  function updateMultiple(ids, diff) {
    ids.forEach((id) => {
      Object.entries(diff).forEach(([key, value]) => {
        obj[id][key] = value;
      });
    });

    notify();
  }

  function updateOne(id, record) {
    Object.entries(record).forEach(([key, value]) => {
      obj[id][key] = value;
    });

    notify();
  }

  function updateCount(count) {
    obj.totalCount = count;
    notify();
  }

  function unloadOne(id) {
    delete obj[id];
    const index = obj.order.indexOf(id);
    if (index !== -1) obj.order.splice(index, 1);

    obj.indices = obj.order.reduce((h, id, index) => {
      h[id] = index;
      return h;
    }, {});

    notify();
  }

  function unload(...ids) {
    ids.forEach((id) => {
      delete obj[id];
    });

    obj.order = obj.order.filter((id) => !ids.includes(id));

    obj.indices = obj.order.reduce((h, id, index) => {
      h[id] = index;
      return h;
    }, {});

    notify();
  }

  function sort(options) {
    sortList(obj, options);
    notify();
  }

  return {
    notify,
    subscribe,
    load,
    insert,
    loadOne,
    updateMultiple,
    updateOne,
    unloadOne,
    unload,
    sort,
    updateCount,
  };
}

export default creatRecordList;
