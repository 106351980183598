import { derived } from "svelte/store";

import { GIT_BRANCH, DEV, IS_PULL_REQUEST, SUPABASE_URL } from "src/env";
import { checkBackendHealth } from "src/api";

import { profile } from "./auth.js";
import { jobs, selectedJobIds as jobIds } from "./jobs.js";

const startupStatus = {
  api: "&#x2610;",
  db: SUPABASE_URL,
  role: undefined,
  mode: IS_PULL_REQUEST === "true" ? "pr" : DEV ? "dev" : undefined,
  on: GIT_BRANCH ?? undefined,
};

async function getBackendStatus() {
  let status;

  try {
    const health = await checkBackendHealth();
    if (health === null || health === "") {
      status = false;
    } else {
      status = true;
    }
  } catch {
    status = false;
  }

  if (status) {
    return "&#x2611;";
  }
  return "&#x2612;";
}

const appStatus = derived(
  [profile, jobs, jobIds],
  ([$profile, $jobs, $jobIds], set, update) => {
    const org = $profile?.organization?.locator;
    const role = $profile?.user_role;

    let job = undefined;

    if ($jobIds.length === 1) {
        const jobId = $jobIds[0];
        if (typeof jobId === "string") {
            const currentJob = $jobs[jobId];
            if (currentJob && "locator" in currentJob) {
                job = currentJob.locator;
            }
        }
    } else {
        job = "[multiple]";
    }

    update((status) => {
      return { ...status, org, job, role };
    });

    getBackendStatus().then((res) => {
        update((status) => {
            return {
                ...status,
                api: res,
            };
        });
    });
  },
  startupStatus,
);

export { appStatus };
