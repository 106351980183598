<script>
  import { navigate } from "svelte-routing";

  import LaminaIcon from "../assets/icons/lamina.svg";
  import ShapeExchangeText from "src/assets/icons/shape-exchange-text.svg";
</script>

<nav class="bg-black navbar flex-none">
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="flex items-center justify-between h-12">
      <div class="flex items-center gap-2">
        <button class="flex-shrink-0 cursor-pointer" on:click={() => navigate("/")}>
          <LaminaIcon />
        </button>
        <div class="flex items-center">
          <button class="cursor-pointer" on:click={() => navigate("/")}>
            <ShapeExchangeText />
          </button>
        </div>
      </div>
      <slot name="center" />
      <slot name="right" />
    </div>
  </div>
</nav>

<style lang="scss">
  .navbar :global(a) {
    @apply px-3 py-2 rounded-md text-sm font-medium text-gray-300;

    &:hover {
      @apply bg-gray-700 text-white;
    }
  }
  .navbar :global(.current) {
    @apply bg-gray-900 text-white;
  }
</style>
